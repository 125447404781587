export function useFormatPrice(price: number, minimumFractionDigits = 0, maximumFractionDigits = 0) {
  return new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(price)
}

export function useFormatNumber(price: number, minimumFractionDigits = 0, maximumFractionDigits = 0) {
  return new Intl.NumberFormat('da-DK', {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(price)
}

export function useFormatCurrency(price: number) {
  // If price is positive, add a plus sign else add a minus sign
  const sign = price > 0 ? '-' : '+'
  return `${sign} ${new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Math.abs(price))}`
}

export function convertStringToArray(input: string): string[] {
  return input.split('\n').filter(line => line.trim() !== '')
}
